import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import MarkdownIt from 'markdown-it';

import formatSlug from './lib/slugFormatter';
import { Layout, Content } from './components/LayoutV2';
import HeroHeader from './components/heroheader';
import SharingOptions from './components/Blog/Sidebar/SharingOptions';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

export const query = graphql`
  query BlogQuery($id: Int!) {
    strapiBlogs(strapiId: { eq: $id }) {
      Title
      Intro
      Content
      CategoryTitle
      Category {
        id
        Title
      }
      Key_Visual {
        publicURL
      }
      Metadata {
        id
        Title
        Description
      }
    }
  }
`;

interface BlogProps {
  data: any;
}

const Blog: FunctionComponent<BlogProps> = ({ data }) => {
  const blog = data.strapiBlogs;
  const metadata = blog.Metadata || { Title: false };
  const keyVisualUrl = blog.Key_Visual ? blog.Key_Visual.publicURL : '';
  const blogLink = formatSlug([
    'blog',
    blog.Category ? blog.Category.Title : 'other',
    blog.Title,
  ]);

  const Markdown = MarkdownIt({ html: true });
  const intro = Markdown.render(blog.Intro || '');
  const content = Markdown.render(blog.Content || '');
  const hasYoutubeVideo =
    content.includes('<iframe') && content.includes('src="https://www.youtube');
  let finalHtml = content;
  let youtubeCode = null;
  
  if (hasYoutubeVideo) {
    const regex = /\/embed\/([^"/]+)/;
    const match = content.match(regex);
    youtubeCode = match ? match[1] : null;
    const contentWithoutVideo = content.replace(
      /<iframe[^>]*>.*?<\/iframe>/gi,
      ''
    );
    finalHtml = contentWithoutVideo;
  }

  const introHTML = <div dangerouslySetInnerHTML={{ __html: intro }} />;
  const contentHTML = <div dangerouslySetInnerHTML={{ __html: finalHtml }} />;

  return (
    <Layout
      className="layout-v2"
      title={metadata.Title || blog.Title}
      desc={metadata.Description || ''}
      noIndex={metadata.noIndex}
    >
      {keyVisualUrl && <HeroHeader backgroundUrl={keyVisualUrl} fullWidth />}
      <Content fullWidth>
        <div className="-mt-80 relative z-10 max-w-2xl mx-3 md:mx-auto p-6 md:p-12 bg-white rounded-3xl shadow-2xl">
          <div className="text-center space-y-10 mb-12 mt-6">
            {blog.CategoryTitle && (
              <div className="flex flex-col justify-center">
                <span className="font-fave text-6xl font-bold text-blue">
                  {blog.CategoryTitle}
                </span>
                <img
                  className="category-highlight"
                  src="/images/highlight-underline-blue.svg"
                  alt="category underline"
                />
              </div>
            )}
            <h2 className="font-worksans font-bold text-4xl text-grey-dark">
              {blog.Title}
            </h2>
          </div>
          <div>
            <SharingOptions to={blogLink} />
          </div>
          <div className="">{introHTML}</div>
          <div className="">{contentHTML}</div>
          {youtubeCode && (
            <>
              <style jsx global>{`
                html,
                body {
                  padding: 0;
                  font-family: -apple-system, BlinkMacSystemFont, Segoe UI,
                    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
                    Helvetica Neue, sans-serif;
                }

                .yt-lite {
                  background-color: #000;
                  position: relative;
                  display: block;
                  contain: content;
                  background-position: center center;
                  background-size: cover;
                  cursor: pointer;
                }

                /* responsive iframe with a 16:9 aspect ratio
                      thanks https://css-tricks.com/responsive-iframes/
                  */
                .yt-lite::after {
                  content: '';
                  display: block;
                  padding-bottom: calc(100% / (16 / 9));
                }
                .yt-lite > iframe {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }

                /* play button */
                .yt-lite > .lty-playbtn {
                  width: 70px;
                  height: 46px;
                  background-color: #212121;
                  z-index: 1;
                  opacity: 0.8;
                  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
                  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
                }
                .yt-lite:hover > .lty-playbtn {
                  background-color: #f00;
                  opacity: 1;
                }
                /* play button triangle */
                .yt-lite > .lty-playbtn:before {
                  content: '';
                  border-style: solid;
                  border-width: 11px 0 11px 19px;
                  border-color: transparent transparent transparent #fff;
                }

                .yt-lite > .lty-playbtn,
                .yt-lite > .lty-playbtn:before {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate3d(-50%, -50%, 0);
                }

                /* Post-click styles */
                .yt-lite.lyt-activated {
                  cursor: unset;
                }
                .yt-lite.lyt-activated::before,
                .yt-lite.lyt-activated > .lty-playbtn {
                  opacity: 0;
                  pointer-events: none;
                }
              `}</style>
              <LiteYouTubeEmbed
                id={youtubeCode}
                title={'Youtube video'}
                noCookie={true}
                poster="sddefault"
              />
            </>
          )}
          <img
            className="absolute -left-80 bottom-12"
            src="/svg/blue-wash-left-02.svg"
            alt="Grey brush stroke positioned on the left side"
          />
          <img
            className="absolute -right-80 top-56"
            src="/svg/pink-wash-right.svg"
            alt="Orange brush stroke positioned on the right bottom side"
          />
        </div>
      </Content>
    </Layout>
  );
};

export default Blog;
