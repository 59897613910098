import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const LinkButton = styled.a`
  display: inline-flex;
  align-items: center;
  height: 44px;
  background-color: #415e9a;
  border-radius: 10px;
  color: #fff !important;
  text-decoration: none;
  padding: 8px 16px;
  transition: all 0.1s ease;
  margin-bottom: 8px;
  cursor: pointer;

  span {
    border-left: 1px solid white;
    height: 18px;
    display: flex;
    align-items: center;
    padding-left: .5rem
  }

  &:hover {
    color: #fff;
  }
`;

const Share = ({ to, ...restProps }) => {
  const [twitterShareUrl, setTwitterhareUrl] = useState(
    'https://twitter.com/home?status='
  );
  const [facebookShareUrl, setFacebookShareUrl] = useState(
    'https://www.facebook.com/sharer/sharer.php?u='
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.origin) {
      const encodedLink = `${window.location.origin}/${encodeURIComponent(to)}`;
      setFacebookShareUrl(
        `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`
      );
      setTwitterhareUrl(`https://twitter.com/home?status=${encodedLink}`);
    }
  }, []);

  return (
    <>
      <div className='flex justify-center align-middle space-x-2 mb-10'>
        <div className='inline-flex btn-share mr-2 px-3 py-2 align-middle bg-white rounded-md'>
          <div className='w-5'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#D6D6D6">
              <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z">
              </path>
            </svg>
          </div>
          <span className='hidden md:inline text-sm text-grey ml-3'>Share</span>
        </div>
        <LinkButton
          target="_blank"
          rel="noopener noreferrer"
          href={facebookShareUrl}
          {...restProps}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="8.569" height="16" viewBox="0 0 8.569 16">
            <path id="Path_143" data-name="Path 143" d="M762.156,105.1l.444-2.9h-2.778v-1.879a1.448,1.448,0,0,1,1.632-1.564h1.263V96.295a15.394,15.394,0,0,0-2.243-.2,3.535,3.535,0,0,0-3.784,3.9V102.2h-2.543v2.9h2.543v7h3.131v-7Z" transform="translate(-754.148 -96.099)" fill="#fff"/>
          </svg>
          <span className='text-sm ml-3'>Facebook</span>
        </LinkButton>
        <LinkButton
          target="_blank"
          rel="noopener noreferrer"
          style={{ backgroundColor: '#55acef' }}
          href={twitterShareUrl}
          {...restProps}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13.169" viewBox="0 0 16 13.169">
            <path id="Path_144" data-name="Path 144" d="M758.063,206.866a6.6,6.6,0,0,1-1.618.461c.26-.044.642-.515.8-.705a2.971,2.971,0,0,0,.537-.979c.014-.028.025-.063,0-.084a.092.092,0,0,0-.086.007,8.241,8.241,0,0,1-1.919.734.128.128,0,0,1-.132-.035,1.564,1.564,0,0,0-.168-.171,3.4,3.4,0,0,0-.929-.57,3.241,3.241,0,0,0-1.425-.229,3.408,3.408,0,0,0-1.352.382,3.489,3.489,0,0,0-1.091.891,3.355,3.355,0,0,0-.651,1.3,3.537,3.537,0,0,0-.035,1.377c.01.077,0,.087-.066.077a10.249,10.249,0,0,1-6.592-3.355c-.077-.088-.118-.088-.182.007a3.342,3.342,0,0,0,.57,3.949c.129.122.262.245.4.357a3.358,3.358,0,0,1-1.272-.357c-.077-.049-.116-.021-.122.067a2.019,2.019,0,0,0,.021.377,3.38,3.38,0,0,0,2.083,2.695,1.982,1.982,0,0,0,.422.13,3.768,3.768,0,0,1-1.248.038c-.091-.017-.125.028-.091.115a3.51,3.51,0,0,0,2.621,2.2c.119.02.238.02.357.048-.007.011-.014.011-.021.021a4.148,4.148,0,0,1-1.79.949,6.41,6.41,0,0,1-2.719.348c-.146-.022-.177-.02-.217,0s-.005.06.042.1c.185.122.374.231.566.336a8.975,8.975,0,0,0,1.817.727,9.681,9.681,0,0,0,9.4-2.2,9.861,9.861,0,0,0,2.6-7.194c0-.1.122-.16.194-.215a6.351,6.351,0,0,0,1.279-1.33.405.405,0,0,0,.085-.255v-.014C758.129,206.824,758.128,206.837,758.063,206.866Z" transform="translate(-742.129 -205.288)" fill="#fff"/>
          </svg>
          <span className='text-sm ml-3'>Twitter</span>
        </LinkButton>
      </div>
    </>
  );
};

export default Share;
